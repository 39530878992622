import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

const p = (c: ReactNode) => <p>{c}</p>
const ol = (c: ReactNode) => <ol>{c}</ol>
const ul = (c: ReactNode) => <ul>{c}</ul>
const li = (c: ReactNode) => <li>{c}</li>
const strong = (c: ReactNode) => <strong>{c}</strong>
const b = (c: ReactNode) => <b>{c}</b>

const FormattedMessageWithTags = ({
  id,
  values,
}: {
  id: string
  values?: Record<string, string | number | boolean | null | undefined | Date | ((c: ReactNode) => JSX.Element)>
}) => (
  <FormattedMessage
    id={id}
    values={{
      p,
      br: <br />,
      ol,
      ul,
      li,
      b,
      strong,
      ...values,
    }}
  />
)

export default FormattedMessageWithTags

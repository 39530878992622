// @ts-nocheck
import 'setimmediate'
import { BigIntResolver } from 'graphql-scalars'
import { makeExecutableSchema } from 'graphql-tools'

import resolvers from './resolvers'
import Accounting from './schema/Accounting.gql'
import Address from './schema/Address.gql'
import AuthMagicLink from './schema/AuthMagicLink.gql'
import AuthProvider from './schema/AuthProvider.gql'
import AuthURLs from './schema/AuthURLs.gql'
import Benefit from './schema/Benefit.gql'
import BrowserId from './schema/BrowserId.gql'
import Calendar from './schema/Calendar.gql'
import Case from './schema/Case.gql'
import CheckClientResponse from './schema/CheckClient.gql'
import Client from './schema/Client.gql'
import ClientHealth from './schema/ClientHealth.gql'
import CommissionStructure from './schema/CommissionStructure.gql'
import Common from './schema/Common.gql'
import CompaniesHouse from './schema/CompaniesHouse.gql'
import Credit from './schema/Credit.gql'
import DashboardsFilter from './schema/DashboardsFilter.gql'
import Dip from './schema/Dip.gql'
import Document from './schema/Document.gql'
import DocumentRequirement from './schema/DocumentRequirement.gql'
import DocumentVerification from './schema/DocumentVerification.gql'
import Filter from './schema/Filter.gql'
import GeneralInsurance from './schema/GeneralInsurance.gql'
import Group from './schema/Group.gql'
import interfaces from './schema/interfaces.gql'
import Kba from './schema/KBA.gql'
import Mortgage from './schema/Mortgage.gql'
import MortgageProductCode from './schema/MortgageProductCode.gql'
import MortgageSourcing from './schema/MortgageSourcing.gql'
import Mutation from './schema/Mutation.gql'
import Note from './schema/Note.gql'
import Notifications from './schema/Notifications.gql'
import Nylas from './schema/Nylas.gql'
import Oauth from './schema/Oauth.gql'
import OrgAndIntroducerAndAdvisorFilter from './schema/OrgAndIntroducerAndAdvisorFilter.gql'
import Organisation from './schema/Organisation.gql'
import OrgRegulatingFilter from './schema/OrgRegulatingFilter.gql'
import Permission from './schema/Permission.gql'
import Property from './schema/Property.gql'
import ProtectionProduct from './schema/ProtectionProduct.gql'
import Query from './schema/Query.gql'
import Reports from './schema/Reports.gql'
import Scheduler from './schema/Scheduler.gql'
import Search from './schema/Search.gql'
import SolutionProtectionProduct from './schema/SolutionProtectionProduct.gql'
import Suitability from './schema/Suitability.gql'
import Survey from './schema/Survey.gql'
import Tag from './schema/Tag.gql'
import Template from './schema/Template.gql'
import User from './schema/User.gql'
import Valuation from './schema/Valuation.gql'
import Verification from './schema/Verification.gql'
import Zendesk from './schema/Zendesk.gql'

export const typeDefs = [
  Accounting,
  Address,
  AuthMagicLink,
  AuthProvider,
  AuthURLs,
  Benefit,
  BrowserId,
  Case,
  Calendar,
  CheckClientResponse,
  Client,
  ClientHealth,
  Common,
  CompaniesHouse,
  CommissionStructure,
  Credit,
  DashboardsFilter,
  Dip,
  Document,
  DocumentRequirement,
  DocumentVerification,
  Filter,
  GeneralInsurance,
  Group,
  Kba,
  Mortgage,
  MortgageProductCode,
  MortgageSourcing,
  Mutation,
  Note,
  Notifications,
  Nylas,
  Oauth,
  Organisation,
  OrgAndIntroducerAndAdvisorFilter,
  OrgRegulatingFilter,
  Permission,
  Property,
  ProtectionProduct,
  Query,
  Reports,
  Scheduler,
  Search,
  SolutionProtectionProduct,
  Suitability,
  Tag,
  Template,
  User,
  Valuation,
  Verification,
  Zendesk,
  interfaces,
  Survey,
]

const schema = makeExecutableSchema({
  resolverValidationOptions: { requireResolversForResolveType: false },
  typeDefs,
  resolvers: { ...resolvers, BigInt: BigIntResolver },
})

export { schema }
export { GraphqlException, LutherException, ExceptionType } from './resolvers/util'
export * from './utils/helpers'
export * from './generated/components'
export * from './utils/clients'
export * from './utils/schemaMapping/client'
export * from './utils/schemaMapping/mapFeeTypeToCanBeAddedToLoan'
export * from './utils/schemaMapping/mapFeeTypeToFeeTiming'
export * from './utils/currentMortgageProductFeeTypes'
export * from './utils'
export * from './constants'
export * from './utils/types'
export * from './resolvers/util'
export * from './api_protection'
export * from './api_general_insurance'
// TODO: Stop using API methods outside of graphql
export type { AddressAndFieldUpdates, PropertyFieldUpdates } from './api'
export { downloadReport, generateReport, fetchProtectedClientFields, CaseLoader } from './api'

import cpRequest from './requesters/clientPortal'
import cpPropertyDataRequest from './requesters/clientPortalPropertyData'
import chRequest from './requesters/companiesHouse'
import request from './requesters/default'
import giRequest from './requesters/generalInsurance'
import oathKeeper from './requesters/oathKeeper'
import oathKeeperPub from './requesters/oathKeeperPub'
import oryLogin from './requesters/oryLogin'
import prRequest from './requesters/protection'
import searchRequest from './requesters/search'

export const requesters = {
  clientPortal: cpRequest,
  clientPortalPropertyData: cpPropertyDataRequest,
  companiesHouse: chRequest,
  default: request,
  generalInsurance: giRequest,
  protection: prRequest,
  oryLogin: oryLogin,
  oathKeeper: oathKeeper,
  oathKeeperPub: oathKeeperPub,
  searchRequest: searchRequest,
}

import { IconButton } from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { CSSObject, styled, Theme } from '@mui/material/styles'

import { caseSidebarDrawWidth } from '../../theme/theme.mui'

export const openedMixin = (theme: Theme): CSSObject => ({
  width: caseSidebarDrawWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 30,
})

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: caseSidebarDrawWidth,
  flexShrink: 0,
  zIndex: 1100,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  '& .MuiDrawer-paper, .MuiPaper-root, .MuiPaper-elevation, .MuiPaper-elevation0': {
    overflow: 'visible',
    border: 'none',
    boxShadow: `${theme.boxShadow}`,
  },
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: 40,
  width: 40,
  color: theme.colours.primary,
  backgroundColor: theme.colours.primary,
  position: 'absolute',
  top: 4,
  left: -20,
  boxShadow: `${theme.boxShadow}`,
  '&:hover': {
    cursor: 'pointer',
    color: theme.colours.primary,
    backgroundColor: theme.colours.primary,
  },
}))

import { InternalRefetchQueriesInclude } from '@apollo/client'
import { useCaseContext } from '@broker-crm-contexts'
import { addMonths } from 'date-fns'
import { useIntl } from 'react-intl'

import {
  Client,
  ClientEidv,
  GetCaseVerificationsDocument,
  GetClientNameDocument,
  GetClientVerificationsDocument,
  Maybe,
  useGetCaseVerificationsQuery,
  useGetClientVerificationsQuery,
  useRunIdVerificationMutation,
  VerificationCollection,
} from '@acre/graphql'

export enum EidvFieldEnum {
  dateOfBirth = 'dateOfBirth',
  name = 'name',
  countryOfCitizenship = 'countryOfCitizenship',
  currentAddress = 'currentAddress',
}

export const messagePrefix = 'clients.profile.IdVerificationNew'

export type MissingEdivFields = {
  numberMissing: number
  missingFieldsString: string
}

export const useEidvMissingFields = (fieldsObject: { [key: string]: Maybe<string> | undefined }): MissingEdivFields => {
  const intl = useIntl()
  const keys = Object.keys(fieldsObject)

  const fields = keys.reduce((acc, key) => {
    const fieldName = intl.formatMessage({ id: `${messagePrefix}.${key}` }).toLowerCase()
    return fieldsObject[key] ? acc : [...acc, fieldName]
  }, [] as string[])

  return { numberMissing: fields.length, missingFieldsString: fields.join(', ') }
}

// Sort the eIDVs by date request, most recent first
export const eidvComparator = (eidv1: ClientEidv, eidv2: ClientEidv) => {
  const date1 = Date.parse(eidv1.eidv_check_requested)
  const date2 = Date.parse(eidv2.eidv_check_requested)
  if (date1 < date2) return 1
  if (date1 > date2) return -1
  return 0
}

export const useRunEidv = (clientId: string, caseId?: string) => {
  const refetchQueries: InternalRefetchQueriesInclude = [
    {
      query: GetClientNameDocument,
      variables: { id: clientId },
      fetchPolicy: 'network-only',
    },
  ]

  if (caseId) {
    refetchQueries.push({
      query: GetCaseVerificationsDocument,
      variables: { id: caseId },
      fetchPolicy: 'network-only',
    })
  } else {
    refetchQueries.push({
      query: GetClientVerificationsDocument,
      variables: { id: clientId },
      fetchPolicy: 'network-only',
    })
  }

  const [runIdVerification, { loading }] = useRunIdVerificationMutation({
    refetchQueries,
    awaitRefetchQueries: true,
  })

  // Runs the mutation to run eidv check
  const runEidvChecks = async () => {
    await runIdVerification({
      variables: { id: clientId },
    })
  }

  return { runEidvChecks, loading }
}

export type ResultsType = {
  eidvStatus: {
    clearEidv: boolean
    considerEidv: boolean
    unidentifiedEidv: boolean
    mortalityEidv: boolean
  }
  expiryDate: Maybe<Date>
  flags: {
    sanction?: Maybe<boolean>
    pep?: Maybe<boolean>
    closeAssociate?: Maybe<boolean>
    specialInterestPerson?: Maybe<boolean>
  }
  clientFlagged: {
    nonCriticallyFlagged: boolean
    criticallyFlagged: boolean
  }
  toShow: JSX.Element | null
}

export enum EdivMessages {
  InsufficientRecords = 'insufficientRecords',
  Unidentified = 'unidentified',
  NonCritical = 'nonCritical',
  CriticalFlag = 'criticalFlag',
  InvalidatedResult = 'invalidatedResult',
}

export const getMostRecentEidv = (client: Client) => {
  const { id_verification_status } = client

  // Everytime an eIDV is run, it gets added to this array and so we want to
  // use the info related to the most recent one, so start by sorting them by date
  const eidvs = id_verification_status ? id_verification_status.eidvs : []
  let sortedEidvs
  if (eidvs && eidvs.length > 0) {
    sortedEidvs = [...eidvs]
    sortedEidvs.sort(eidvComparator)
  }

  const mostRecentEidv = sortedEidvs && sortedEidvs[0]
  return mostRecentEidv
}

export const getEidvDates = (eidv?: ClientEidv) => {
  const hasCompletedDate = Boolean(eidv?.eidv_check_completed)
  return {
    expiryDate: hasCompletedDate ? addMonths(new Date(eidv!.eidv_check_completed), 3) : null,
    completedDate: hasCompletedDate ? new Date(eidv!.eidv_check_completed) : null,
  }
}

export type VerificationsResult = {
  verificationCollections?: VerificationCollection[]
  fromCase: boolean
  caseId?: string
  loading: boolean
}

/**
 * Hook that returns the verifications from client or case
 * @returns verification collections from client or case
 * @returns fromCase boolean which indicates if verifications came from case or from client
 */

export const useVerifications = ({
  clientId,
  show_pass,
}: {
  clientId?: string
  show_pass?: boolean
}): VerificationsResult => {
  const { id: caseId } = useCaseContext()

  const { data: clientVerifications, loading: clientVerificationsLoading } = useGetClientVerificationsQuery({
    variables: { id: clientId!, ...(show_pass && { show_pass }) }, // Don't pass show_pass param if no value
    fetchPolicy: 'network-only',
    skip: Boolean(caseId) || !clientId,
  })

  const { data: caseVerifications, loading: caseVerificationsLoading } = useGetCaseVerificationsQuery({
    variables: { id: caseId!, ...(show_pass && { show_pass }) }, // Don't pass show_pass param if no value
    fetchPolicy: 'network-only',
    skip: !caseId,
  })

  const verificationCollections = caseId
    ? caseVerifications?.case.details.verifications?.verification_collections
    : clientVerifications?.clientVerifications.verification_collections

  return {
    verificationCollections: verificationCollections ?? undefined,
    fromCase: !!caseId,
    caseId: caseId,
    loading: caseVerificationsLoading || clientVerificationsLoading,
  }
}

export const eidvNeedsToBeRunVerificationId = 'a4e4334a-3749-48d2-90fc-651a1658dd4f'
export const eidvHashMatchVerificationId = '7fa91a15-6d4d-4a6c-8a6e-8c0f45f2371b'
export const eidvRunIsTooOldVerificationId = '2ccd8f67-3491-49c7-bf63-5e1c7c2e49f3'

export const verificationValidEidvCheckStatus = '35f8041f-c427-41a7-b092-8f6311bf6ab6'
export const verificationEidvProofIdExists = '25c29e20-1464-48ad-abe3-1ab928c963c1'
export const verificationEidvProofAddressExists = '423145aa-39f6-4bb7-be47-79ba6267da87'
export const verificationEidvProofIdVerified = 'bd6b7735-0df8-4f3c-9887-d3846c5bec75'
export const verificationEidvProofAddressVerified = 'd63c028e-3774-4c08-b64a-58040884bffe'
export const documentVerifications = [
  verificationEidvProofIdExists,
  verificationEidvProofAddressExists,
  verificationEidvProofIdVerified,
  verificationEidvProofAddressVerified,
]

export const financialCrimeFlagVerificationId = '5977f29a-f164-4476-91d8-c35ff16a2650'
export const pepFlagVerificationId = '270826fc-f565-4e58-ab5c-35ce210ac103'
export const amlFlagVerificationId = 'dd18e02a-8b1e-4404-b8c2-5fdde58720b7'
export const eidvCaseFlagVerificationIds = [
  financialCrimeFlagVerificationId,
  pepFlagVerificationId,
  amlFlagVerificationId,
]

export const eidvHasExpired = '2ccd8f67-3491-49c7-bf63-5e1c7c2e49f3'

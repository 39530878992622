import { User } from '@acre/graphql'

type getUserOrganisationIdsProps = {
  user: User
}

type getUserOrganisationIdsReturnType = {
  userOrganisationId: string
  firstReviewingForHint: string
  all: string[]
}

export const getUserOrganisationIds = ({ user }: getUserOrganisationIdsProps): getUserOrganisationIdsReturnType => {
  let organisationIds: getUserOrganisationIdsReturnType = {
    userOrganisationId: '',
    firstReviewingForHint: '',
    all: [],
  }
  if (!user) return organisationIds
  const { organisation_id, organisation } = user

  if (organisation_id) {
    organisationIds.all.push(organisation_id)
    organisationIds.userOrganisationId = organisation_id
  }

  if (!organisation) return organisationIds

  const { metadata } = organisation
  if (Array.isArray(metadata) && metadata.length) {
    metadata.forEach((datum) => {
      if (datum && datum.name === 'reviewing_for_hint' && datum.string_value) {
        organisationIds.all.push(datum.string_value)
        organisationIds.firstReviewingForHint = !organisationIds.firstReviewingForHint ? datum.string_value : ''
      }
    })
  }

  return organisationIds
}

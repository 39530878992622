import React, { ComponentProps, ReactNode } from 'react'
import { styled, unstable_composeClasses } from '@mui/material'
import classnames from 'classnames'

import { Variant } from '../../utils/constants'
import { getLabelUtilityClass } from './labelClasses'

export interface LabelProps {
  component?: 'label' | 'legend'
  text?: ReactNode
  htmlFor?: string
  isDisabled?: boolean
  isMissing?: boolean
  isIncomplete?: boolean
  verificationError?: string
  variant?: Variant
  secondaryText?: ReactNode
  customMarginBottom?: string
  lineHeight?: number | null
}

const useUtilityClasses = () => {
  const slots = {
    root: ['root'],
  }

  return unstable_composeClasses(slots, getLabelUtilityClass, undefined)
}

export const LabelRoot = styled('label', {
  name: 'Label',
  slot: 'root',
  shouldForwardProp: (prop) =>
    prop !== 'isMissing' &&
    prop !== 'isDisabled' &&
    prop !== 'isIncomplete' &&
    prop !== 'variant' &&
    prop !== 'customMarginBottom' &&
    prop !== 'secondaryText' &&
    prop !== 'lineHeight' &&
    prop !== 'verificationError',
})<LabelProps>(({ theme, isDisabled, variant, isMissing, customMarginBottom, lineHeight }) => {
  const _variant = variant as Variant

  return {
    color: !isMissing ? theme.colours.new.baseMidColour : theme.colours.danger,
    display: 'block',
    fontSize:
      _variant === 'compact' || _variant === 'compactLeftAligned' || _variant === 'compactTopAligned'
        ? '12px !important'
        : '14px',
    marginBottom:
      (!!customMarginBottom && customMarginBottom) || (_variant === 'compactTopAligned' && theme.spacers.size4) || 0,
    lineHeight: lineHeight || 1.4,
    opacity: isDisabled ? 0.8 : 1,
    width: '100%',
    paddingLeft: _variant === 'compactRightAligned' ? theme.spacers.size8 : 0,
    paddingRight: _variant === 'compactLeftAligned' ? theme.spacers.size8 : 0,
  }
})

const StyledRequiredLabel = styled('label')(
  ({ theme }) => `
  color: ${theme.colours.baseMid};
`,
)

const StyledLegend = LabelRoot.withComponent('legend')

const Label = ({
  text,
  htmlFor,
  isDisabled = false,
  isMissing = false,
  isIncomplete = false,
  verificationError,
  variant,
  secondaryText,
  customMarginBottom,
  className,
  lineHeight,
  ...props
}: ComponentProps<typeof LabelRoot>) => {
  const slotClasses = useUtilityClasses()

  const generateContent = () => {
    if (isMissing) return 'Missing'
    if (isIncomplete) return 'Incomplete'
    if (verificationError) return verificationError
  }
  const content = generateContent()

  return content ? (
    <LabelRoot
      className={classnames(slotClasses.root, className)}
      customMarginBottom={customMarginBottom}
      htmlFor={htmlFor}
      isDisabled={isDisabled}
      variant={variant}
      isMissing={isMissing}
      component="label"
      lineHeight={lineHeight}
      {...props}
    >
      {text && secondaryText ? (
        <>
          <>{text}</> <StyledRequiredLabel>{secondaryText}</StyledRequiredLabel>
        </>
      ) : (
        text
      )}
    </LabelRoot>
  ) : (
    <LabelRoot
      className={slotClasses.root}
      customMarginBottom={customMarginBottom}
      variant={variant}
      htmlFor={htmlFor}
      isDisabled={isDisabled}
      secondaryText={Boolean(secondaryText)}
      component="legend"
      lineHeight={lineHeight}
      {...props}
    >
      {text && secondaryText ? (
        <>
          <>{text}</> <StyledRequiredLabel>{secondaryText}</StyledRequiredLabel>
        </>
      ) : (
        text
      )}
    </LabelRoot>
  )
}

export type LegendProps = Pick<LabelProps, 'text' | 'isDisabled' | 'secondaryText' | 'isMissing' | 'variant'>

const Legend = ({ text, isDisabled, isMissing, secondaryText, variant }: LegendProps) => (
  <StyledLegend isDisabled={isDisabled} isMissing={isMissing} variant={variant}>
    {text && secondaryText ? (
      <>
        <>{text}</> <StyledRequiredLabel>{secondaryText}</StyledRequiredLabel>
      </>
    ) : (
      text
    )}
  </StyledLegend>
)

export default Label
export { Legend }

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const MasonryLayout = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
}))

export const MasonrySection = styled('div')<{ fullWidth?: boolean }>(({ theme, fullWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${theme.colours.new.gray200}`,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  gap: theme.spacing(8),


  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },

  width: fullWidth ? '100%' : 'auto',
  '&:last-of-type': {
    borderBottom: 0,
  },
}))

export const MasonryColumn = styled('div')<{ flexWidth?: string; }>(
  ({ flexWidth, theme }) => `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${flexWidth ? `flex: ${flexWidth};` : ''};
  & + div {
    border-left: 1px solid ${theme.colours.baseLight};
  }s
`,
)

export const BorderlessMasonryColumn = styled('div')<{ flexWidth?: string; }>(
  ({ flexWidth }) => `
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${flexWidth ? `flex: ${flexWidth};` : ''};
`,
)

export const MasonryCell = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacers.size24,
  paddingLeft: theme.spacers.size32,
  paddingRight: theme.spacers.size32,
  paddingBottom: theme.spacers.size16,
  '& + div': {
    borderTop: `1px solid ${theme.colours.baseLight}`,
  },
  '&:last-of-type': {
    height: '100%',
  },
}))
